<template>
  <div id="login" class="grid">
    <div class="pg-header">
      <h2>Logga in</h2>
    </div>
    <div class="pg-form">
      <div class="pg-form-group">
        <label class="pg-label">Användarnamn</label>
        <input v-model="username" class="pg-input" type="text" name="username" />
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Lösenord</label>
        <input v-model="password" class="pg-input" type="password" @keyup.enter="login" />
        <div v-if="errorMessage" class="errorMessageText">
          {{ errorMessage }}
        </div>
      </div>
      <div class="pg-form-group">
        <button class="btn-primary" @click="login">Logga in</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  created() {},
  methods: {
    async login() {
      let response = null;
      try {
        response = await this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        });
      } catch (e) {
        console.error(e);
        // TODO : FIX ERROR LOG
      } finally {
        // let loggedIn = false;
        if (response.success == 1) {
          this.$router.replace({
            name: 'WorkOrders'
          });
        } else {
          this.errorMessage = 'Fel användarname eller lösenord. Vänligen försök igen';
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
